import React from "react"
import { useToaster } from "react-hot-toast/headless"
import styled from "styled-components"
import { ContentArea } from "templates/ContentArea"
import { useSpring, animated, config } from "@react-spring/web"
import devices from "styles/devices"

const SelectedDealToastContainer = styled.div`
  position: fixed;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: end;
  justify-content: center;
  background: ${({ active }) => (active ? "rgba(0, 0, 0, 0.2)" : "transparent")};
  transition: background 0.3s;

  & > * {
    pointer-events: all;
  }
`

const Toast = styled(animated.div)`
  background: #ffffff;
  box-shadow: 0px 16px 16px rgba(0, 9, 40, 0.08);
  border-radius: 16px;
  padding: 20px 24px;
  margin: 40px 0;
  font-size: 18px;
  gap: 32px;
  line-height: 1.5;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;

  @media screen and ${devices.bp_sm} {
    display: flex;
  }
`

const Notification = ({ component, toast, handlers }) => {
  const styles = useSpring({
    from: { opacity: 0, transform: "translateY(100%)" },
    to: { opacity: 1, transform: "translateY(0%)" },
    config: config.gentle,
    delay: 100,
    reverse: !toast.visible,
  })
  return (
    <Toast
      onMouseEnter={handlers.startPause}
      onMouseLeave={handlers.endPause}
      style={{
        ...styles,
        animation: "none",
      }}
    >
      {React.cloneElement(component, toast)}
    </Toast>
  )
}

export const Notifications = ({ notificationComponent }) => {
  const { toasts, handlers } = useToaster({ duration: Infinity })

  return (
    <SelectedDealToastContainer
      active={toasts.filter((t) => t.visible).length}
      onMouseEnter={handlers.startPause}
      onMouseLeave={handlers.endPause}
    >
      <ContentArea style={{ margin: 0, paddingBottom: 0 }}>
        {toasts.map((toast) => (
          <Notification
            key={toast.id}
            component={notificationComponent}
            toast={toast}
            handlers={handlers}
          />
        ))}
      </ContentArea>
    </SelectedDealToastContainer>
  )
}
