import { colors, Colors } from "./colors"

export interface ThemeConfig {
  name: string

  pageTitle: string
  faviconURL: string
  mainLogoURL: string

  /**
   * All the shades of color used in the Mojo app.
   * Choose specific hues or use the shortcuts in `primary` and `secondary`.
   */
  colors: Colors & {
    primary: {
      /**
       * Shortcut for vivid-500
       */
      one: string
      /**
       * Shortcut for neutral-900
       */
      two: string
      /**
       * Shortcut for white
       */
      three: string
    }
    secondary: {
      /**
       * Shortcut for vivid-0
       */
      one: string
      /**
       * Shortcut for peach-100
       */
      two: string
      /**
       * Shortcut for yellow-500
       */
      three: string
    }
  }

  /**
   * @deprecated - use the colors object
   */
  primary: string
  /**
   * @deprecated - use the colors object
   */
  secondary: string
  /**
   * @deprecated - use the colors object
   */
  tertiary: string
  /**
   * @deprecated - use the colors object
   */
  cta: string
  /**
   * @deprecated - use the colors object
   */
  ctaButton: string
  /**
   * @deprecated - use the colors object
   */
  error: string
  /**
   * @deprecated - use the colors object
   */
  backgroundColour?: string
  /**
   * @deprecated - use the colors object
   */
  shade?: {
    0: string
    10: string
    20: string
    30: string
    40: string
    50: string
    60: string
    70: string
    80: string
    90: string
    100: string
  }
  maxWidth?: string
  border?: {
    default: string
  }
  radius?: {
    default: string
    large: string
  }
  opacity?: {
    default: number
  }

  footerBackground?: string
  footerText?: string

  /**
   * @deprecated - use the colors object
   */
  primaryOne?: string
  /**
   * @deprecated - use the colors object
   */
  primaryTwo?: string
  /**
   * @deprecated - use the colors object
   */
  primaryThree?: string
  /**
   * @deprecated - use the colors object
   */
  primaryFour?: string

  /**
   * @deprecated - use the colors object
   */
  secondaryOne?: string
  /**
   * @deprecated - use the colors object
   */
  secondaryTwo?: string
  /**
   * @deprecated - use the colors object
   */
  secondaryThree?: string

  /**
   * @deprecated - use the colors object
   */
  shade100?: string
  /**
   * @deprecated - use the colors object
   */
  shade90?: string
  /**
   * @deprecated - use the colors object
   */
  shade80?: string
  /**
   * @deprecated - use the colors object
   */
  shade70?: string
  /**
   * @deprecated - use the colors object
   */
  shade60?: string
  /**
   * @deprecated - use the colors object
   */
  shade50?: string
  /**
   * @deprecated - use the colors object
   */
  shade40?: string
  /**
   * @deprecated - use the colors object
   */
  shade30?: string
  /**
   * @deprecated - use the colors object
   */
  shade20?: string
  /**
   * @deprecated - use the colors object
   */
  shade10?: string

  /**
   * @deprecated - use the colors object
   */
  grey?: string
  /**
   * @deprecated - use the colors object
   */
  grey_dark?: string
  /**
   * @deprecated - use the colors object
   */
  blue?: string

  /**
   * @deprecated - use the colors object
   */
  buttonShadow: string

  /**
   * @deprecated - use the colors object
   */
  background?: string
  /**
   * @deprecated - use the colors object
   */
  activeNavItemBackground?: string
  /**
   * @deprecated - use the colors object
   */
  borderColor?: string
  /**
   * @deprecated - use the colors object
   */
  infoColor?: string
  /**
   * @deprecated - use the colors object
   */
  lightInfoColour?: string
  /**
   * @deprecated - use the colors object
   */
  navColour?: string
  /**
   * @deprecated - use the colors object
   */
  navItemColour?: string

  /**
   * @deprecated - use the colors object
   */
  starColour?: string

  landerHeader?: ((isRemo: boolean) => string) | string
  landerBodyText?: string
  landerLeadText?: string
  landerBullet1?: string
  landerBullet2?: string
  landerBullet3?: string
  landerBullet4?: string
  landerBullet5?: string
  landerSecondaryText?: string
  landerComplianceText?: string
  landerCTA?: string
  illustrations: {
    lander: string
    routing: string | null
  }
  privacyPolicy?: string
  showPoweredBy?: boolean
  poweredByVariant?: string
  mojoBlurb?: string | boolean
  fontFamily?: string

  borderRadius?: number
  footerCopy?: string[]
}

export const defaultTheme: ThemeConfig = {
  name: "Default",
  pageTitle: "My Mojo",
  faviconURL: "https://mojomortgages.com/favicon.png",
  mainLogoURL: "https://static.mojomortgages.com/mojologo.svg",

  colors: {
    ...colors,
    primary: {
      one: colors.vivid[500],
      two: colors.neutral[900],
      three: colors.white,
    },
    secondary: {
      one: colors.vivid[0],
      two: colors.peach[100],
      three: colors.yellow[500],
    },
  },

  primary: colors.vivid[500],
  primaryThree: colors.neutral[900],
  primaryFour: "#FFFFFF",
  primaryTwo: colors.yellow[900],
  secondaryOne: colors.yellow[0],
  secondaryThree: colors.error[500],
  backgroundColour: colors.neutral[0],
  shade: {
    100: "#ffffff",
    90: "#f5f7fb",
    80: "#e9edf5",
    70: "#dbe1eb",
    60: "#cad1dd",
    50: "#b6becb",
    40: "#9ea7b2",
    30: "#828a93",
    20: "#61676e",
    10: "#3c4044",
    0: "#16181a",
  },
  secondary: "#BEE3FF",
  tertiary: "#000928",
  cta: "#00A0FF",
  ctaButton: "#073ED9",
  error: "#ED5A5A",
  footerBackground: "#000928",
  footerText: "#FFFFFF",
  maxWidth: "1216px",
  border: {
    default: "2px",
  },
  radius: {
    default: "4px",
    large: "8px",
  },
  opacity: {
    default: 0.4,
  },

  primaryOne: "#073ED9",

  secondaryTwo: "#F5F7FB",

  shade100: "#FFFFFF",
  shade90: "#F5F7FB",
  shade80: "#e7ebf4",
  shade70: "#d8dce7",
  shade60: "#bfc3d0",
  shade50: "#7f8394",
  shade40: "#656a7e",
  shade30: "#4c5269",
  shade20: "#333a53",
  shade10: "#1a223f",

  grey: "#b5b5b5",
  grey_dark: "#434344",
  blue: "#073ed8",

  buttonShadow: "#052ea3",

  background: "#F5F7FB",
  activeNavItemBackground: "#e7ebf4",
  borderColor: "#e0e0e0",
  infoColor: "#fbbb5b",
  lightInfoColour: "#fde9db",
  navColour: "white",
  navItemColour: "#444444",

  starColour: "#0045FF",

  landerHeader: (isRemo) =>
    `Let’s get a ${isRemo ? "remortgage" : "mortgage"} that’s perfect for you.`,
  landerBodyText: "",
  landerLeadText:
    "All we need to do is ask a few questions and do a soft credit check. Don’t worry, it won’t affect your credit score.",
  landerBullet1: "Free eligibility and affordability review",
  landerBullet2: "Thousands of mortgage deals",
  landerBullet3: "70+ mortgage lenders",
  landerBullet4: "",
  landerBullet5: "",
  landerSecondaryText: "",
  landerComplianceText: undefined,
  landerCTA: "Get Started",
  illustrations: {
    lander: "https://static.mojomortgages.com/partner-illustrations/bankrate/bbq.svg",
    routing: null,
  },
  privacyPolicy: "",
  showPoweredBy: false,
  poweredByVariant: "partnership",
  mojoBlurb: "",
  fontFamily: "'TT Wellingtons', Arial, sans-serif",
}
