import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Icon, IconType } from "components/atoms/Icon"
import { FullApplication } from "models/FullApplication"
import { BookingCalendar } from "components/organisms/NurtureCalendar/BookingCalendar"
import { AppointmentCard } from "pages/Application/Eligibility/Approved/AppointmentCard"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { IS_DEV } from "../../../../Config"
import devices from "styles/devices"
import { RootThemeContext } from "contexts/root-theme-context"
import { useExperienceTLS } from "hooks/useExperienceTLS"
import { AppointmentBookingContext } from "contexts/appointment-booking-context"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
  z-index: 10;
`

const CalendarModal = styled.div`
  position: fixed;
  z-index: 11;
  background: #f5f5f5;
  border-radius: 12px;
  overflow-y: scroll;
  max-height: 80vh;
  width: 90vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and ${devices.bp_lg} {
    width: unset;
    overflow-y: visible;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  min-height: 393px;
  justify-content: center;
  align-items: center;
`

const CloseButton = styled.a`
  display: block;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
`

const InvisibleAcuityId = styled.p`
  display: none;
`

export interface Props {
  isBookingVisible: boolean
  setIsBookingVisible: (isVisible: boolean) => void
  bookingComplete: boolean
  setBookingComplete: (isComplete: boolean) => void
  fullApplication: FullApplication
  crm?: boolean
  refreshApplication: (applicationToUpdate: FullApplication | null, silent: boolean) => void
}

const ACUITY_GHOST_APPT_TYPE = 52507112

export function Booking({
  isBookingVisible = false,
  setIsBookingVisible,
  bookingComplete,
  setBookingComplete,
  fullApplication,
  crm,
  refreshApplication,
}: Props) {
  const [adviser, setAdviser] = useState("A Mortgage Expert")
  const [appointmentDateTime, setAppointmentDateTime] = useState<Date>(null)
  const [appointmentDuration, setAppointmentDuration] = useState<number>(null)
  const [appTypeId, setAppTypeId] = useState<number | null>(
    fullApplication.tls?.appointmentTypeId ?? null
  )
  const [appointmentData, setAppointmentData] = useState<{
    appointmentDate: Date
    assignee: string
    calendlyEventName: string
    calendlyEventDuration: number
  }>({
    appointmentDate: null,
    assignee: null,
    calendlyEventName: "",
    calendlyEventDuration: 0,
  })
  const { acuityId } = useContext(AppointmentBookingContext)
  const { setBlockScroll } = useContext(RootThemeContext)
  const { experienceTLS, isLoading } = useExperienceTLS(fullApplication.friendlyId)

  async function checkExistingAppointment() {
    const existingAppointment = fullApplication.appointments.find((appt) => {
      return (
        appt.calendlyEventName === "Mojo Mortgage Appointment" &&
        new Date(appt.appointmentDate) >= new Date() &&
        !appt.isCancelled
      )
    })
    if (!existingAppointment) return
    setAdviser(existingAppointment.assignee)
    const apptDate = new Date(existingAppointment.appointmentDate)
    setAppointmentDuration(existingAppointment.calendlyEventDuration)
    setAppointmentDateTime(
      new Date(
        Date.UTC(
          apptDate.getFullYear(),
          apptDate.getMonth(),
          apptDate.getDate(),
          apptDate.getHours(),
          apptDate.getMinutes()
        )
      )
    )

    setBookingComplete(true)
  }

  const setNewAppointmentData = ({ assignee, appointmentDate, calendlyEventDuration }) => {
    setAdviser(assignee)
    setAppointmentDuration(calendlyEventDuration)
    setAppointmentDateTime(new Date(appointmentDate))
    refreshApplication(null, true)
    setBookingComplete(true)
  }

  // if in test/dev change appointment type to their test counterparts
  // this will show up as "test" in calendars
  useEffect(() => {
    if (!isLoading) {
      if (appTypeId === null) {
        // To support older applications, that don't have the appointmentTypeId saved against them.
        // We should remove this by Novemeber 2024.
        const appTypeId = +experienceTLS.rule.appointmentId
        setAppTypeId(appTypeId)
        if (IS_DEV) {
          if (appTypeId === 16310820) {
            setAppTypeId(16313875)
          } else {
            setAppTypeId(9907738)
          }
        }
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (appointmentData.appointmentDate && appointmentData.assignee) {
      setNewAppointmentData(appointmentData)
      return
    }
    if (fullApplication.appointments?.length) {
      checkExistingAppointment()
    }
  }, [bookingComplete, isBookingVisible, appointmentData])

  useEffect(() => setBlockScroll(isBookingVisible), [isBookingVisible])

  return (
    <>
      {IS_DEV && (
        <InvisibleAcuityId id="acuity-id" data-testid="acuity-id">
          {acuityId}
        </InvisibleAcuityId>
      )}
      {bookingComplete && (
        <AppointmentCard
          adviser={adviser}
          appointmentDate={appointmentDateTime}
          appointmentDuration={appointmentDuration}
          friendlyId={fullApplication.friendlyId}
          isGhostAppt={appTypeId === ACUITY_GHOST_APPT_TYPE}
        />
      )}
      {isBookingVisible && (
        <>
          <Overlay onClick={() => setIsBookingVisible(false)} />
          <CalendarModal>
            <CloseButton onClick={() => setIsBookingVisible(false)}>
              <Icon type={IconType.Cross} />
            </CloseButton>
            {!appTypeId && (
              <LoaderContainer>
                <Loader theme={LoaderTheme.Outline} size={150} />
              </LoaderContainer>
            )}
            {appTypeId && (
              <BookingCalendar
                application={fullApplication}
                calendarId={null}
                appTypeId={appTypeId}
                skipToCalendar={true}
                crm={crm}
                bookingComplete={setBookingComplete}
                refreshApplication={refreshApplication}
                isModal={true}
                setAppointmentData={setAppointmentData}
                clearPhoneNumber={experienceTLS.reviewPhoneNumber}
              />
            )}
          </CalendarModal>
        </>
      )}
    </>
  )
}
