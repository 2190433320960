import React from "react"
import Accord from "assets/images/lenders/white/Accord.png"
import AldermoreMortgages from "assets/images/lenders/white/Aldermore Mortgages.png"
import Atom from "assets/images/lenders/white/Atom.png"
import BankOfIreland from "assets/images/lenders/white/Bank of Ireland.png"
import Barclays from "assets/images/lenders/white/Barclays.png"
import BathBuildingSociety from "assets/images/lenders/white/Bath Building Society.png"
import BeverleyBuildingSociety from "assets/images/lenders/white/Beverley Building Society.png"
import BirminghamMidshires from "assets/images/lenders/white/Birmingham Midshires.png"
import BluestoneMortgages from "assets/images/lenders/white/Bluestone Mortgages.png"
import BMSolutions from "assets/images/lenders/white/BM Solutions.png"
import CambridgeBuildingSociety from "assets/images/lenders/white/Cambridge Building Society.png"
import ChorleyBuildingSociety from "assets/images/lenders/white/Chorley Building Society.png"
import ClydesdaleBank from "assets/images/lenders/white/Clydesdale Bank.png"
import CoventryBuildingSociety from "assets/images/lenders/white/Coventry Building Society.png"
import DigitalMortgages from "assets/images/lenders/white/Digital Mortgages.png"
import FoundationHomeLoans from "assets/images/lenders/white/Foundation Home Loans.png"
import FurnessBuildingSociety from "assets/images/lenders/white/Furness Building Society.png"
import GenerationHome from "assets/images/lenders/white/Generation Home.png"
import GodivaMortgages from "assets/images/lenders/white/Godiva Mortgages.png"
import Halifax from "assets/images/lenders/white/Halifax.png"
import HinckleyandRugbyBuildingSociety from "assets/images/lenders/white/Hinckley and Rugby Building Society.png"
import HSBC from "assets/images/lenders/white/HSBC.png"
import KensingtonMortgages from "assets/images/lenders/white/Kensington Mortgages.png"
import KentReliance from "assets/images/lenders/white/Kent Reliance.png"
import Landbay from "assets/images/lenders/white/Landbay.png"
import LeedsBuildingSociety from "assets/images/lenders/white/Leeds Building Society.png"
import LeekUnitedBuildingSociety from "assets/images/lenders/white/Leek United Building Society.png"
import LloydsBank from "assets/images/lenders/white/Lloyds Bank.png"
import MansfieldBuildingSociety from "assets/images/lenders/white/Mansfield Building Society.png"
import MetroBank from "assets/images/lenders/white/Metro Bank.png"
import MeltonBuildingSociety from "assets/images/lenders/white/Melton Building Society.png"
import MonmouthshireBuildingSociety from "assets/images/lenders/white/Monmouthshire Building Society.png"
import MortgageWorks from "assets/images/lenders/white/Mortgage Works.png"
import NationwideBuildingSociety from "assets/images/lenders/white/Nationwide Building Society.png"
import Natwest from "assets/images/lenders/white/Natwest.png"
import NewcastleBuildingSociety from "assets/images/lenders/white/Newcastle Building Society.png"
import NewburyBuildingSociety from "assets/images/lenders/white/Newbury Building Society.png"
import NottinghamBuildingSociety from "assets/images/lenders/white/Nottingham Building Society.png"
import ParagonBank from "assets/images/lenders/white/Paragon Bank.png"
import PepperMoney from "assets/images/lenders/white/Pepper Money.png"
import Platform from "assets/images/lenders/white/Platform.png"
import PostOffice from "assets/images/lenders/white/Post Office.png"
import PreciseMortgages from "assets/images/lenders/white/Precise Mortgages.png"
import Principality from "assets/images/lenders/white/Principality.png"
import ProgressiveBuildingSociety from "assets/images/lenders/white/Progressive Building Society.png"
import RBS from "assets/images/lenders/white/RBS.png"
import Santander from "assets/images/lenders/white/Santander.png"
import ScottishWidowsBank from "assets/images/lenders/white/Scottish Widows Bank.png"
import SkiptonBuildingSociety from "assets/images/lenders/white/Skipton Building Society.png"
import Together from "assets/images/lenders/white/Together.png"
import TSB from "assets/images/lenders/white/TSB.png"
import VidaHomeloans from "assets/images/lenders/white/Vida Homeloans.png"
import VirginMoney from "assets/images/lenders/white/Virgin Money.png"

export const LenderIcon = ({ lenderName }: { lenderName: string }) => {
  switch (lenderName) {
    case "Accord":
      return <img src={Accord} />
      break
    case "Aldermore Mortgages":
      return <img src={AldermoreMortgages} />
      break
    case "Atom Bank":
      return <img src={Atom} />
      break
    case "Bank of Ireland":
      return <img src={BankOfIreland} />
      break
    case "Barclays Bank":
      return <img src={Barclays} />
      break
    case "Bath Building Society":
      return <img src={BathBuildingSociety} />
      break
    case "Bluestone Mortgages":
      return <img src={BluestoneMortgages} />
      break
    case "BM Solutions":
      return <img src={BMSolutions} />
      break
    case "Cambridge Building Society":
      return <img src={CambridgeBuildingSociety} />
      break
    case "Clydesdale Bank":
      return <img src={ClydesdaleBank} />
      break
    case "Coventry Building Society":
      return <img src={CoventryBuildingSociety} />
      break
    case "Generation Home":
      return <img src={GenerationHome} />
      break
    case "Halifax":
      return <img src={Halifax} />
      break
    case "Hinckley and Rugby Building Society":
      return <img src={HinckleyandRugbyBuildingSociety} />
      break
    case "HSBC":
      return <img src={HSBC} />
      break
    case "Kensington Mortgages":
      return <img src={KensingtonMortgages} />
      break
    case "Kent Reliance":
      return <img src={KentReliance} />
      break
    case "Landbay":
      return <img src={Landbay} />
      break
    case "Leeds Building Society":
      return <img src={LeedsBuildingSociety} />
      break
    case "Leek United Building Society":
      return <img src={LeekUnitedBuildingSociety} />
      break
    case "Lloyds Bank":
      return <img src={LloydsBank} />
      break
    case "Mansfield Building Society":
      return <img src={MansfieldBuildingSociety} />
      break
    case "Metro Bank":
      return <img src={MetroBank} />
      break
    case "Monmouthshire Building Society":
      return <img src={MonmouthshireBuildingSociety} />
      break
    case "Mortgage Works, The":
      return <img src={MortgageWorks} />
      break
    case "Nationwide Building Society":
      return <img src={NationwideBuildingSociety} />
      break
    case "Natwest":
      return <img src={Natwest} />
      break
    case "Newcastle Building Society":
      return <img src={NewcastleBuildingSociety} />
      break
    case "Nottingham Building Society":
      return <img src={NottinghamBuildingSociety} />
      break
    case "Paragon Bank":
      return <img src={ParagonBank} />
      break
    case "Pepper Money":
      return <img src={PepperMoney} />
      break
    case "Platform":
      return <img src={Platform} />
      break
    case "Precise Mortgages":
      return <img src={PreciseMortgages} />
      break
    case "Principality":
      return <img src={Principality} />
      break
    case "Progressive Building Society":
      return <img src={ProgressiveBuildingSociety} />
      break
    case "Royal Bank of Scotland":
      return <img src={RBS} />
      break
    case "Santander UK PLC":
      return <img src={Santander} />
      break
    case "Scottish Widows Bank":
      return <img src={ScottishWidowsBank} />
      break
    case "Skipton Building Society":
      return <img src={SkiptonBuildingSociety} />
      break
    case "Together":
      return <img src={Together} />
      break
    case "TSB":
      return <img src={TSB} />
      break
    case "Vida Homeloans":
      return <img src={VidaHomeloans} />
      break
    case "Virgin Money":
      return <img src={VirginMoney} />
      break
    case "Post Office":
      return <img src={PostOffice} />
      break
    case "Godiva Mortgages":
      return <img src={GodivaMortgages} />
      break
    case "Digital Mortgages":
      return <img src={DigitalMortgages} />
      break
    case "Beverley Building Society":
      return <img src={BeverleyBuildingSociety} />
      break
    case "Chorley Building Society":
      return <img src={ChorleyBuildingSociety} />
      break
    case "Foundation Home Loans":
      return <img src={FoundationHomeLoans} />
      break
    case "Furness Building Society":
      return <img src={FurnessBuildingSociety} />
      break
    case "Melton Building Society, The":
      return <img src={MeltonBuildingSociety} />
      break
    case "Newbury Building Society":
      return <img src={NewburyBuildingSociety} />
      break
    case "Birmingham Midshires":
      return <img src={BirminghamMidshires} />
      break

    default:
      return <span>{lenderName}</span>
      break
  }
}

export default LenderIcon
